import { Service } from '@/global';
import { Label } from '@/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/ui/select';

interface ServicePickerProps {
  handleServiceChange: (value: string) => void;
  service: string | undefined;
  services: any;
}

export function ServicePicker({
  handleServiceChange,
  service,
  services,
}: ServicePickerProps) {
  return (
    <div className="w-[200px]">
      <Label>Servizio</Label>
      <Select
        value={service || services?.[0]?.id}
        onValueChange={handleServiceChange}
      >
        <SelectTrigger>
          <SelectValue placeholder="All" />
        </SelectTrigger>
        <SelectContent style={{ zIndex: 9999 }}>
          {services?.map((service: Service) => (
            <SelectItem key={service.id} value={service.id}>
              {service.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}
