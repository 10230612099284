import { cn } from '@/lib/utils';

interface HeadingProps {
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  side?: React.ReactNode;
  icon?: React.ReactNode;
  className?: string;
}

export function Heading({
  title,
  description,
  side,
  icon,
  className,
}: HeadingProps) {
  return (
    <div
      className={cn('border-b border-gray-200 pb-5 print:hidden', className)}
    >
      <div className="flex items-start justify-between">
        <div className="flex items-center gap-3">
          {icon && <div className="shrink-0">{icon}</div>}
          <div className="flex flex-col">
            <h3 className="text-2xl font-semibold text-gray-900 ">{title}</h3>
            {description && (
              <p className="max-w-4xl mt-2 text-sm text-gray-500">
                {description}
              </p>
            )}
          </div>
        </div>
        {side}
      </div>
    </div>
  );
}
