'use client';

import * as React from 'react';
import { format } from 'date-fns';
import { cn } from '@/lib/utils';
import { Button } from '@/ui/button';
import { Calendar } from '@/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/ui/popover';
import { CalendarDaysIcon } from '@heroicons/react/20/solid';

export function DatePicker({
  className,
  value,
  onChange,
}: {
  className?: string;
  value: Date | undefined;
  onChange: (value: Date | undefined) => void;
}) {
  return (
    <div className={cn('grid gap-2', className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={'outline'}
            className={cn(
              'justify-start text-left font-normal',
              !value && 'text-muted-foreground'
            )}
          >
            <CalendarDaysIcon className="mr-1 size-5 text-slate-600" />
            {value ? format(value, 'LLL dd, y') : <span>Pick a date</span>}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="single"
            defaultMonth={value}
            selected={value}
            showWeekNumber={false}
            onSelect={onChange}
          />
          <div className="p-3">
            <Button variant={'outline'} onClick={() => onChange(undefined)}>
              Clear
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
