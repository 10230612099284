import { requestQuote } from '@/lib/api';
import { useApiSend } from '@/lib/api/client';
import { QuoteRequest, QuoteResponse } from '@/lib/api/types';
import { useEffect, useState } from 'react';

/**
 * This hook is used to fetch a quote for a given quote request.
 * It uses the `requestQuote` API function to retrieve the quote.
 */
export function useRequestQuote(quoteRequest: QuoteRequest) {
  const [quoteResponse, setQuoteResponse] = useState<QuoteResponse>();
  const { isPending, mutate } = useApiSend<QuoteResponse, QuoteRequest>(
    (data) => {
      return requestQuote(data);
    },
    {}
  );

  useEffect(() => {
    mutate(quoteRequest, {
      onSuccess: (data) => {
        setQuoteResponse(data);
      },
      onError: (error) => {
        console.log('error', error);
      },
    });
  }, [quoteRequest, mutate]);

  return {
    isPending,
    quoteResponse,
  };
}

/**
 * This hook is used to fetch quotes for multiple quote requests.
 * It uses the `requestQuote` API function to retrieve the quotes.
 */
export function useRequestQuotes(quoteRequests: QuoteRequest[]) {
  const [quoteResponses, setQuoteResponses] = useState<QuoteResponse[]>([]);
  const { isPending, mutate } = useApiSend<QuoteResponse[], QuoteRequest[]>(
    async (requests) => {
      const quotes = await Promise.all(
        requests.map((request) => requestQuote(request))
      );
      return quotes;
    },
    {}
  );

  useEffect(() => {
    // Convert quoteRequests to a stable string for comparison
    // const requestKey = JSON.stringify(
    //   quoteRequests.map((req) => ({
    //     ...req,
    //     date: req.date.toISOString(),
    //   }))
    // );

    mutate(quoteRequests, {
      onSuccess: (data) => {
        setQuoteResponses(data);
      },
      onError: (error) => {
        console.log('error', error);
      },
    });
  }, [
    JSON.stringify(
      quoteRequests.map((req) => ({
        ...req,
        date: req.date.toISOString(),
      }))
    ),
  ]);

  return {
    isPending,
    quoteResponses,
  };
}
