import { useEffect, useState } from 'react';
import Layout from './layout';
import { Heading } from '@/ui/heading';
import { Loader2 } from 'lucide-react';
import { useApiGet } from '@/lib/api/client';
import { getAvailability, getSuppliedServices } from '@/lib/api';
import { Availability } from '@/global';
import { addDays, format, isSameDay } from 'date-fns';
import store from '../store';
import { ShiftPicker } from '@/components/shared/shift-picker';
import { ServicePicker } from '@/components/shared/service-picker';
import { AvailabilityBlock } from '@/components/availability/availability-block';
import { DatePicker } from '@/ui/date-picker';
import { Label } from '@/ui/label';

export default function AvailabilityPage() {
  const [service, setService] = useState<string>();
  const [shift, setShift] = useState<string>();
  const [startDate, setStartDate] = useState<Date>(new Date());
  const daysToShow = 20;

  const { data: services = [], isLoading: isLoadingServices } = useApiGet(
    ['supplied-services'],
    async () => await getSuppliedServices(store.getState().user.data!.id),
    {
      enabled: true,
      refetchOnWindowFocus: true,
      retry: 0,
    }
  );

  useEffect(() => {
    if (services.length > 0) {
      setService(services[0].id);
      setShift(services[0].shifts[0].id);
    }
  }, [services]);

  const currentService = services.find((s) => s.id === service);
  const currentShift = currentService?.shifts.find((s) => s.id === shift);
  const {
    data: availabilities = [],
    isLoading,
    isFetching,
    refetch,
  } = useApiGet(
    [],
    async () =>
      await getAvailability({
        service: service!,
        shift: shift!,
        from: startDate,
        to: addDays(startDate, daysToShow),
      }),
    {
      enabled: !!service && !!shift,
      refetchOnWindowFocus: true,
      retry: 0,
    }
  );

  useEffect(() => {
    if (currentService && currentShift) {
      refetch();
    }
  }, [currentService, currentShift, startDate, refetch]);

  if (!currentService) {
    return <div>Servizio non trovato</div>;
  }

  const handleServiceChange = (value: string) => {
    setService(value);
    setShift(services.find((s) => s.id === value)?.shifts[0].id);
  };

  const handleShiftChange = (value: string) => {
    setShift(value);
  };

  const getAvailabilityForDay = (
    day: Date,
    resource: string
  ): Availability | undefined => {
    return availabilities?.find(
      (a) => a.date === format(day, 'yyyy-MM-dd') && a.resource === resource
    );
  };

  return (
    <Layout>
      <div className="block w-full h-full">
        <Heading className="p-4" title="Configurazione disponibilità" />
        <div className="p-4">
          <div className="flex gap-4">
            <ServicePicker
              handleServiceChange={handleServiceChange}
              service={service}
              services={services}
            />

            <ShiftPicker
              handleShiftChange={handleShiftChange}
              service={service}
              services={services}
              shift={shift}
            />

            <div>
              <Label>Data inizio</Label>
              <DatePicker
                value={startDate}
                onChange={(value) => setStartDate(value ?? new Date())}
              />
            </div>
          </div>
        </div>

        <div className="p-4">
          {isLoading || isLoadingServices || isFetching || !availabilities ? (
            <div className="flex items-center justify-center h-full">
              <Loader2 className="w-4 h-4 animate-spin" />
            </div>
          ) : (
            <div className="relative overflow-x-auto border border-gray-200 rounded-lg">
              {/* Header with dates */}
              <div
                className="flex flex-col"
                style={{ minWidth: `${daysToShow * 100}px` }}
              >
                <div className="flex">
                  <div className="sticky top-0 left-0 z-10 w-48 p-3 bg-white border-b border-r border-gray-200 shrink-0"></div>
                  <div className="flex flex-1 border-b border-gray-200">
                    {[...Array(daysToShow)].map((_, i) => {
                      const date = addDays(startDate, i);
                      const isToday = isSameDay(date, new Date());
                      const isWeekend = [0, 6].includes(date.getDay());
                      return (
                        <div
                          key={i}
                          className={`flex-1 p-3 text-center border-r border-gray-200 last:border-r-0 ${
                            isToday ? 'bg-slate-200' : ''
                          } ${isWeekend ? 'bg-slate-100' : ''}`}
                          style={{ width: '100px' }}
                        >
                          <div className="text-sm font-semibold">
                            {new Date(date).toLocaleDateString('it-IT', {
                              weekday: 'short',
                            })}
                          </div>
                          <div className="text-sm text-gray-600">
                            {new Date(date).toLocaleDateString('it-IT', {
                              day: 'numeric',
                              month: 'short',
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div
                className="flex flex-col"
                style={{ minWidth: `${daysToShow * 100}px` }}
              >
                {currentService?.resources?.map((resource) => (
                  <div
                    key={resource.id}
                    className="flex border-b border-gray-200 last:border-b-0"
                  >
                    <div className="sticky left-0 z-10 w-48 p-3 bg-white border-r border-gray-200 shrink-0">
                      <div className="text-sm font-medium">{resource.name}</div>
                    </div>
                    <div className="flex flex-1">
                      {[...Array(daysToShow)].map((_, dayIndex) => (
                        <AvailabilityBlock
                          availability={getAvailabilityForDay(
                            addDays(startDate, dayIndex),
                            resource.id
                          )}
                          serviceId={service}
                          shiftId={shift}
                          date={format(
                            addDays(startDate, dayIndex),
                            'yyyy-MM-dd'
                          )}
                          resourceId={resource.id}
                          key={dayIndex}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}
