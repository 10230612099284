import clsx from 'clsx';
import {
  BetweenVerticalStart,
  LoaderIcon,
  TableColumnsSplit,
} from 'lucide-react';
import { PropsWithChildren, useState } from 'react';
import classNames from 'clsx';
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
} from '@headlessui/react';
import {
  Bars3Icon,
  HomeIcon,
  TicketIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { NavLink, useNavigate } from 'react-router-dom';
import { ASSETS_URL } from '@/consts';
import { useIsFetching } from '@tanstack/react-query';
import { useIsMutating } from '@tanstack/react-query';
import store from '../store';

export default function Layout({
  main,
  side,
  children,
}: PropsWithChildren<{
  main?: React.ReactNode;
  side?: React.ReactNode;
}>) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { user: u, unAuthenticate } = store();
  const navigate = useNavigate();

  const user = u?.data;
  console.log(user);
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  const navigation = [
    { name: 'Dashboard', href: '/', icon: HomeIcon, end: true },
    {
      name: 'Reservations',
      href: '/reservations',
      icon: TicketIcon,
      end: true,
    },
    ...(user?.is_supplier
      ? [
          {
            name: 'Price Policies',
            href: '/supplier/price-policies',
            icon: TableColumnsSplit,
            end: true,
          },
          {
            name: 'Availability',
            href: '/supplier/availability',
            icon: BetweenVerticalStart,
            end: true,
          },
        ]
      : []),
  ];

  function handleLogout() {
    unAuthenticate();
    navigate('/');
  }

  if (!user) {
    return null;
  }

  const renderSidebar = () => (
    <div className="fixed inset-0 flex">
      <DialogPanel
        transition
        className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-trangray-x-full"
      >
        <TransitionChild>
          <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
            <button
              type="button"
              onClick={() => setSidebarOpen(false)}
              className="-m-2.5 p-2.5"
            >
              <span className="sr-only">Close sidebar</span>
              <XMarkIcon aria-hidden="true" className="text-white size-6" />
            </button>
          </div>
        </TransitionChild>

        <div className="flex flex-col px-6 pb-2 overflow-y-auto bg-gray-900 grow gap-y-5 ring-1 ring-white/10">
          <div className="flex items-center h-16 shrink-0">
            <img alt="Booking" src="/logo.jpg" className="w-auto h-8 rounded" />
          </div>
          <nav className="flex flex-col flex-1">
            <ul role="list" className="flex-1 -mx-2 space-y-1">
              {navigation.map((item) => (
                <li key={item.name}>
                  <NavLink
                    to={item.href}
                    end
                    className={({ isActive }) =>
                      classNames(
                        'group flex gap-x-3 rounded-md p-3 text-sm/6 font-semibold',
                        {
                          'bg-gray-800 text-white': isActive,
                          'text-gray-400 hover:bg-gray-800 hover:text-white':
                            !isActive,
                        }
                      )
                    }
                  >
                    <item.icon aria-hidden="true" className="size-6 shrink-0" />
                    <span>{item.name}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </DialogPanel>
    </div>
  );

  const renderStaticSidebar = () => (
    <div className="hidden lg:fixed lg:inset-y-0 lg:left-0 lg:z-50 lg:block lg:w-20 lg:overflow-y-auto lg:bg-gray-900 lg:pb-4">
      {isFetching > 0 || isMutating > 0 ? (
        <div className="flex items-center justify-center h-16 p-2 text-center bg-slate-600 text-slate-200">
          <LoaderIcon className="w-6 h-6 animate-spin" />
        </div>
      ) : null}
      <nav className="mt-4">
        <ul className="flex flex-col items-center space-y-1">
          {navigation.map((item) => (
            <li key={item.name}>
              <NavLink
                to={item.href}
                end
                className={({ isActive }) =>
                  classNames(
                    'group flex gap-x-3 rounded-md p-3 text-sm/6 font-semibold',
                    {
                      'bg-gray-800 text-white': isActive,
                      'text-gray-400 hover:bg-gray-800 hover:text-white':
                        !isActive,
                    }
                  )
                }
              >
                <item.icon aria-hidden="true" className="size-6 shrink-0" />
                <span className="sr-only">{item.name}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );

  const renderHeader = () => (
    <div className="sticky top-0 z-[10] flex items-center h-16 px-4 bg-white border-b border-gray-200 shadow-sm shrink-0 gap-x-4 sm:gap-x-6 sm:px-6 lg:px-8">
      <button
        type="button"
        onClick={() => setSidebarOpen(true)}
        className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
      >
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon aria-hidden="true" className="size-6" />
      </button>

      <div aria-hidden="true" className="w-px h-6 bg-gray-900/10 lg:hidden" />

      <div className="flex self-stretch flex-1 gap-x-4 lg:gap-x-6">
        <div className="flex-1"></div>
        <div className="flex items-center gap-x-4 lg:gap-x-6">
          <div
            aria-hidden="true"
            className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
          />

          <Menu as="div" className="relative">
            <MenuButton className="-m-1.5 flex items-center p-1.5">
              <span className="sr-only">Open user menu</span>
              {user?.avatar && (
                <img
                  alt=""
                  src={ASSETS_URL + user.avatar}
                  className="rounded-full size-8 bg-gray-50"
                />
              )}
              <span className="hidden lg:flex lg:items-center">
                <span
                  aria-hidden="true"
                  className="ml-4 font-semibold text-gray-900 text-sm/6"
                >
                  {user.first_name} {user.last_name}
                </span>
                <ChevronDownIcon
                  aria-hidden="true"
                  className="ml-2 text-gray-400 size-5"
                />
              </span>
            </MenuButton>
            <MenuItems
              transition
              className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <MenuItem>
                <button
                  onClick={handleLogout}
                  className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                >
                  Sign out
                </button>
              </MenuItem>
            </MenuItems>
          </Menu>
        </div>
      </div>
    </div>
  );

  return (
    <div className="h-full">
      <Dialog
        open={sidebarOpen}
        onClose={setSidebarOpen}
        className="relative z-50 lg:hidden"
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
        />
        {renderSidebar()}
      </Dialog>

      {renderStaticSidebar()}

      <div className="lg:pl-20">
        {renderHeader()}

        <main className={clsx('pb-24', { 'md:mr-96 ': !!side })}>
          <div className={clsx({ 'w-full ': !side })}>{main ?? children}</div>
        </main>
      </div>

      {side && (
        <aside className="bottom-0 w-full overflow-y-auto border-l border-gray-200 bg-white/70 md:fixed md:right-0 md:top-16 md:w-96 md:block">
          {side}
        </aside>
      )}
    </div>
  );
}
