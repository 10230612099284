import { getPrices } from '@/lib/api';
import { useApiSend } from '@/lib/api/client';
import { PriceRequest, PriceResponse, QuoteRequest } from '@/lib/api/types';
import { useEffect, useState } from 'react';

/**
 * This hook is used to fetch prices for a given quote request.
 * It uses the `getPrices` API function to retrieve the prices.
 */
export function usePrices(quoteRequest: QuoteRequest) {
  const [prices, setPrices] = useState<PriceResponse>();
  const { isPending, mutate } = useApiSend<PriceResponse, PriceRequest>(
    (data) => {
      return getPrices(data);
    },
    {}
  );

  useEffect(() => {
    mutate(
      {
        service: quoteRequest.service,
        date: quoteRequest.date,
        shift: quoteRequest.shift,
      },
      {
        onSuccess: (data) => {
          setPrices(data);
        },
        onError: (error) => {
          console.log('error', error);
        },
      }
    );
  }, [quoteRequest.date, quoteRequest.shift, quoteRequest.service, mutate]);

  return { prices, isPending };
}
