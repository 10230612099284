import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ServiceCard from '@/components/booking/service-card';
import BookingForm from '@/components/booking/booking-form';
import { getSellableService } from '@/lib/api';
import { useApiGet } from '@/lib/api/client';
import ErrorScreen from '@/components/error-screen';
import { SkeletonLoader } from '@/ui/skeleton-loader';
import { Heading } from '@/ui/heading';
import { Service } from '@/global';

import Layout from './layout';

function Booking() {
  const { t } = useTranslation();
  const { id } = useParams();

  const {
    data: service,
    isLoading,
    error,
    isError,
    isLoadingError,
    isFetching,
  } = useApiGet<Service>([], async () => await getSellableService(id), {
    enabled: true,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  if ((!service && (!isLoading || !isFetching)) || isError || isLoadingError) {
    return (
      <ErrorScreen
        message={error?.message ?? t('common.errors.unexpected.description')}
      />
    );
  }

  const renderContent = () => {
    if (isLoading || isFetching) {
      return <SkeletonLoader className="p-4" />;
    }

    if (service) {
      return (
        <>
          <Heading
            className="p-4"
            title={
              service.name?.charAt(0).toUpperCase() +
              service.name?.slice(1).toLowerCase()
            }
            description={service.subtitle}
          />
          <BookingForm service={service} />
        </>
      );
    }

    return null;
  };

  const renderSideContent = () => {
    if (isLoading || isFetching) {
      return <SkeletonLoader className="p-3" />;
    }

    if (service) {
      return <ServiceCard service={service} />;
    }

    return null;
  };

  return (
    <Layout side={<div className="p-4 w-full">{renderSideContent()}</div>}>
      <div className="w-full">{renderContent()}</div>
    </Layout>
  );
}

export default Booking;