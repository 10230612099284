import { format } from 'date-fns';
import { useState } from 'react';
import VariantRow from './variant-row';
import { QuoteRequest } from '@/lib/api/types';
import store from '@/app/store';
import ModalOrDrawer from '../modal-or-drawer';
import CalendarModal from './calendar-modal';
import CustomerForm from './customer-form';
import Loading from '../loading';
import { usePrices } from './use-prices';
import { useRequestQuote } from './use-request-quote';
import { useBook } from './use-book';
import { Service } from '@/global';
import { Separator } from '@/ui/separator';
import { ClockIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { ErrorAlert } from '@/ui/error-alert';
import { FormRow } from '@/ui/form-row';
import { useNavigate } from 'react-router-dom';
import { errorMapping, getTotalRequestedAmount } from './utils';
import { PriceTotals } from './prices-totals';
import { AvailabilityPreview } from './availability-preview';
import { Button } from '@/ui/button';
import { CalendarIcon } from 'lucide-react';

interface Props {
  service: Service;
}

export default function BookingForm({ service }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    user: { data: user },
  } = store((s) => s);

  const [quoteRequest, setQuoteRequest] = useState<QuoteRequest>({
    service: service.id,
    date: new Date(),
    shift: service?.shifts[0]?.id,
    seller: user.id,
    variants: service.variants.reduce((a, v) => ({ ...a, [v.id]: 0 }), {}),
  });

  const { prices } = usePrices(quoteRequest);
  const { isPending: isQuoting, quoteResponse } = useRequestQuote(quoteRequest);
  const { isPending: isBooking, mutate: book } = useBook();

  const handleUpdateQuoteRequest = (date: Date, shift: string) => {
    setQuoteRequest({ ...quoteRequest, date, shift });
  };

  const handleCustomerFormSubmit = (data: any) => {
    book(
      {
        ...quoteRequest,
        seller: store.getState().user.data.id,
        date: format(quoteRequest.date, 'yyyy-MM-dd'),
        customerName: data.name,
        customerEmail: data.email,
        customerPhone: data.phone,
      },
      {
        onSuccess(result) {
          navigate(`/reservations/${result.id}`);
        },
      }
    );
  };

  return (
    <div className="w-full p-4 space-y-4">
      {!isQuoting && !!errorMapping(quoteRequest, quoteResponse) && (
        <ErrorAlert
          title={t(
            `components.booking-form.errors.${errorMapping(
              quoteRequest,
              quoteResponse
            )}.title`
          )}
          description={t(
            `components.booking-form.errors.${errorMapping(
              quoteRequest,
              quoteResponse
            )}.description`
          )}
        />
      )}

      {isQuoting || (isBooking && <Loading />)}

      {!isBooking && (
        <div className="w-full py-2 space-y-6 md:py-6 md:space-y-10">
          <FormRow
            title={t('components.booking-form.departure-date.title')}
            subtitle={t('components.booking-form.departure-date.description')}
          >
            <div className="flex items-center ">
              <ModalOrDrawer
                disabled={isQuoting}
                trigger={
                  <div className="inline-block">
                    <CalendarTrigger
                      date={quoteRequest.date}
                      shiftName={
                        service.shifts?.find((s) => s.id === quoteRequest.shift)
                          ?.name ??
                        t('components.booking-form.select-date-time')
                      }
                      shiftTime={
                        service.shifts?.find((s) => s.id === quoteRequest.shift)
                          ?.from ?? ''
                      }
                    />
                  </div>
                }
              >
                {(setOpen) => (
                  <CalendarModal
                    selectedDate={quoteRequest.date}
                    selectedShift={quoteRequest.shift}
                    shifts={service.shifts}
                    onSelected={(date, shift, close) => {
                      handleUpdateQuoteRequest(date, shift);
                      close && setOpen(false);
                    }}
                  />
                )}
              </ModalOrDrawer>
              {getTotalRequestedAmount(quoteRequest) > 0 && !isQuoting && (
                <ModalOrDrawer
                  trigger={
                    <Button variant="link" className="text-sm">
                      <CalendarIcon className="w-4 h-4 mr-1" />
                      {t('components.booking-form.availability-preview.title')}
                    </Button>
                  }
                >
                  {(setOpen) => (
                    <div className="p-4">
                      <AvailabilityPreview
                        quoteRequest={quoteRequest}
                        service={service}
                        days={4}
                        onUpdate={(date, shift) => {
                          handleUpdateQuoteRequest(date, shift.id);
                          setOpen(false);
                        }}
                      />
                    </div>
                  )}
                </ModalOrDrawer>
              )}
            </div>
          </FormRow>

          <FormRow
            title={t('components.booking-form.variants.title')}
            subtitle={t('components.booking-form.variants.description')}
          >
            <div className="grid grid-cols-1 gap-3 xl:grid-cols-2">
              {service.variants?.map((variant) => (
                <VariantRow
                  key={variant.id}
                  price={prices?.prices?.[variant.id]?.total ?? 0}
                  notSellable={
                    quoteResponse?.prices?.[variant.id]?.type === 'error' ||
                    prices?.prices?.[variant.id]?.type === 'error'
                  }
                  variant={variant}
                  isLoading={isQuoting}
                  amount={quoteRequest.variants[variant.id]}
                  onSetValue={(value) => {
                    setQuoteRequest({
                      ...quoteRequest,
                      variants: {
                        ...quoteRequest.variants,
                        [variant.id]: value,
                      },
                    });
                  }}
                />
              ))}
            </div>
          </FormRow>

          {!isQuoting &&
            !isBooking &&
            prices &&
            !errorMapping(quoteRequest, quoteResponse) && (
              <FormRow
                title={t('components.booking-form.totals.title')}
                subtitle={t('components.booking-form.totals.description')}
              >
                <PriceTotals prices={prices} quoteRequest={quoteRequest} />
              </FormRow>
            )}

          {!isQuoting &&
            !isBooking &&
            !errorMapping(quoteRequest, quoteResponse) && (
              <FormRow
                title={t('components.booking-form.customer.title')}
                subtitle={t('components.booking-form.customer.description')}
              >
                <CustomerForm onSubmit={handleCustomerFormSubmit} />
              </FormRow>
            )}
        </div>
      )}
    </div>
  );
}

const CalendarTrigger = ({
  date,
  shiftName,
  shiftTime,
  disabled,
}: {
  date: Date;
  shiftName: string;
  shiftTime: string;
  disabled?: boolean;
}) => {
  return (
    <button
      disabled={disabled}
      className="flex items-center w-full p-2 px-6 space-x-5 bg-white border rounded-lg shadow-sm"
    >
      <div className="flex flex-col items-center justify-center aspect-square">
        <div className="text-3xl font-semibold leading-none">
          {format(date, 'd')}
        </div>
        <div className="text-sm uppercase text-slate-600">
          {format(date, 'MMM')}
        </div>
      </div>
      <Separator orientation="vertical" className="h-16" />

      <span className="flex text-left">
        <span className="flex flex-col text-sm">
          <span className="font-medium text-slate-900">{shiftName}</span>
          <span className="flex items-center space-x-1 text-slate-700">
            <ClockIcon className="mr-1 size-4 text-slate-700" />
            {shiftTime}
          </span>
        </span>
      </span>
    </button>
  );
};