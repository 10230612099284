import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { useMeasure } from '@uidotdev/usehooks';
import CalendarShifts from './calendar-shifts';
import { calAnimation, shiftAnimation, styles } from './calendar-configuration';
import { Shift } from '@/global';
import { Heading } from '@/ui/heading';
import { useTranslation } from 'react-i18next';

const STEPS = ['CALENDAR', 'SHIFT'];

export type CalendarProps = {
  onSelected: (date: Date, shift: string, close?: boolean) => void;
  shifts: Shift[];
  selectedDate: Date;
  selectedShift: string;
};

export default function CalendarModal({
  selectedDate,
  selectedShift,
  shifts,
  onSelected,
}: CalendarProps) {
  const { t } = useTranslation();
  const [step, setStep] = useState<(typeof STEPS)[number]>(STEPS[0]);
  const [ref, { width = 100, height = 100 }] = useMeasure();
  const divSize = {
    width: width ?? 'auto',
    minWidth: width ?? 'auto',
    height: height ?? 'auto',
  };

  const handleDayClick = (date: Date) => {
    onSelected(date, selectedShift, shifts.length > 1 ? false : true);
    setStep(STEPS[1]);
  };

  const handleShiftSelect = (shift: string) => {
    onSelected(selectedDate, shift, true);
  };

  return (
    <motion.div
      className="relative flex items-center overflow-hidden"
      ref={ref}
    >
      <AnimatePresence mode="popLayout">
        {step === STEPS[0] && (
          <motion.div
            className="w-full space-y-4"
            {...calAnimation(width ?? 0)}
            style={divSize}
          >
            <Heading
              className="p-4"
              title={t('components.booking-form.departure-day.title')}
              description={t('components.booking-form.departure-day.description')}
            />
            <div className="p-4">
              <DayPicker
                initialFocus
                mode="default"
                defaultMonth={selectedDate}
                selected={selectedDate}
                onDayClick={handleDayClick}
                disabled={{ before: new Date() }}
                showOutsideDays
                classNames={styles}
                numberOfMonths={1}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence mode="popLayout">
        {step === STEPS[1] && (
          <motion.div
            className="w-full"
            {...shiftAnimation(width ?? 0)}
            style={divSize}
          >
            <Heading
              className="p-4"
              title={t('components.booking-form.departure-time.title')}
              description={t('components.booking-form.departure-time.description')}
            />
            <CalendarShifts
              shifts={shifts}
              selectedShift={selectedShift}
              selectedDate={selectedDate}
              onSelected={handleShiftSelect}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}