import { Service, Shift } from '@/global';
import { Label } from '@/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/ui/select';

interface ShiftPickerProps {
  handleShiftChange: (value: string) => void;
  service: string | undefined;
  services: any;
  shift: string | undefined;
}

export function ShiftPicker({
  handleShiftChange,
  service,
  services,
  shift,
}: ShiftPickerProps) {
  return (
    <div className="w-[200px]">
      <Label>Turno</Label>
      <Select value={shift} onValueChange={handleShiftChange}>
        <SelectTrigger>
          <SelectValue placeholder="All" />
        </SelectTrigger>
        <SelectContent style={{ zIndex: 9999 }}>
          {services
            ?.find((s: Service) =>
              s ? s.id === service : services?.[0]?.shifts?.[0]?.id
            )
            ?.shifts.map((shift: Shift) => (
              <SelectItem key={shift.id} value={shift.id}>
                {shift.name}
              </SelectItem>
            ))}
        </SelectContent>
      </Select>
    </div>
  );
}
