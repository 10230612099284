import { Button } from '@/ui/button';
import { Input } from '@/ui/input';
import { Label } from '@/ui/label';
import store from '../store';
import { Formik } from 'formik';
import { useState } from 'react';
import { Alert, AlertDescription, AlertTitle } from '@/ui/alert';
import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import Splash from '@/components/login/splash';

/* eslint-disable-next-line */
export interface LoginProps {}

export function Login() {
  const { login } = store();
  const [loginError, setLoginError] = useState(false);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await login(values);
      setSubmitting(false);
    } catch (error) {
      console.error(error);
      setLoginError(true);
      setSubmitting(false);
    }
  };

  return (
    <div className="w-full lg:grid min-h-screen lg:grid-cols-2">
      <div className="hidden bg-muted lg:block">
        <Splash />
      </div>
      <div className="flex items-center justify-center py-12">
        <div className="mx-auto grid w-[350px] gap-6">
          <div className="grid gap-2 text-center">
            <h1 className="text-3xl font-bold">Login</h1>
            <p className="text-balance text-muted-foreground">
              Enter your email below to login to your account
            </p>
          </div>

          {loginError && (
            <Alert variant="destructive">
              <ExclamationTriangleIcon className="h-4 w-4" />
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>The credentials are invalid!</AlertDescription>
            </Alert>
          )}

          <Formik
            onReset={() => setLoginError(false)}
            initialValues={{ email: '', password: '' }}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="grid gap-4">
                  <div className="grid gap-2">
                    <Label htmlFor="email">Email</Label>
                    <Input
                      id="email"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      placeholder="me@example.com"
                      required
                    />
                    {errors.email && touched.email && errors.email}
                  </div>
                  <div className="grid gap-2">
                    <div className="flex items-center">
                      <Label htmlFor="password">Password</Label>
                    </div>
                    <Input
                      id="password"
                      type="password"
                      required
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    {errors.password && touched.password && errors.password}
                  </div>

                  <Button
                    type="submit"
                    className="w-full"
                    disabled={isSubmitting}
                  >
                    Login
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Login;