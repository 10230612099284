import { SPLASH_IMAGES } from '@/consts';
import { getRandomElementFromArray } from '@/lib/utils';

export function Splash() {
  return (
    <div className="relative h-full">
      <img
        src={`${getRandomElementFromArray(SPLASH_IMAGES)}`}
        alt="Placeholder"
        className="h-full w-full object-cover"
      />
      <div className="px-12 lg:px-16 pt-[200px] pb-16 bottom-0 absolute text-foreground bg-gradient-to-t from-background/95 via-background/95 to-background/0 w-full space-y-3">
        <h1 className=" text-4xl font-bold  leading-none">
          Reserve the best experiences in Sardinia
        </h1>
        <p className="text-muted-foreground text-sm">
          Discover Adventure, Embrace Nature: Your Gateway to Exploration&#33;&quot;
          invites you to embark on unforgettable journeys into the heart of
          nature&apos;s wonders.
        </p>
      </div>
    </div>
  );
}

export default Splash;
