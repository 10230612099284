import { useEffect, useState } from 'react';
import Timeline, {
  DateHeader,
  SidebarHeader,
  TimelineHeaders,
  TimelineMarkers,
  TodayMarker,
} from 'react-calendar-timeline';
import moment from 'moment';
import 'moment/locale/it';
import Layout from './layout';
import { Heading } from '@/ui/heading';
import { Loader2 } from 'lucide-react';
import { useApiGet, useApiSend } from '@/lib/api/client';
import {
  getPricePolicies,
  getSuppliedServices,
  updatePricePolicy,
} from '@/lib/api';
import { Price, PriceDirectusUsers, Variant } from '@/global';
import Item from '@/components/timeline/item';
import { addDays, subDays } from 'date-fns';
import store from '../store';
import EditPrice from '@/components/prices/edit-price';
import {
  buildUniqueGroups,
  createTimelineItems,
  addFolderItems,
} from '@/components/timeline/utils';
import { Button } from '@/ui/button';
import { PlusCircledIcon } from '@radix-ui/react-icons';
import { ShiftPicker } from '@/components/shared/shift-picker';
import { ServicePicker } from '@/components/shared/service-picker';

export default function App() {
  const [filter, setFilter] = useState<string>();
  const [service, setService] = useState<string>();
  const [shift, setShift] = useState<string>();
  const [selectedItem, setSelectedItem] =
    useState<Partial<Price & { variant: Partial<Variant> }>>();

  const { data: services = [], isLoading: isLoadingServices } = useApiGet(
    ['supplied-services'],
    async () => await getSuppliedServices(store.getState().user.data!.id),
    {
      enabled: true,
      refetchOnWindowFocus: true,
      retry: 0,
    }
  );

  useEffect(() => {
    if (services.length > 0) {
      setService(services[0].id);
      setShift(services[0].shifts[0].id);
    }
  }, [services]);

  const {
    data = [],
    isLoading,
    isFetching,
    refetch,
  } = useApiGet(
    [
      'price-policies',
      JSON.stringify({
        service: service,
        shift: shift,
      }),
    ],
    async () =>
      await getPricePolicies({
        service: service,
        shift: shift,
      }),
    {
      enabled: true,
      refetchOnWindowFocus: true,
      retry: 0,
    }
  );

  const { mutate: updatePolicy, isPending: isUpdating } = useApiSend(
    async ({ id, data }: { id: string; data: Partial<Price> }) =>
      await updatePricePolicy(data, id),
    {
      onMutate: async ({ data }: { id: string; data: Partial<Price> }) => {
        const previousData = data;
        return { previousData };
      },
      onSuccess: () => {
        refetch();
      },
    }
  );

  const timelineItems = addFolderItems(
    createTimelineItems(data),
    buildUniqueGroups(data, filter)
  );

  const groups = buildUniqueGroups(data, filter);

  const currentService = services.find((s) => s.id === service);
  const currentShift = currentService?.shifts.find((s) => s.id === shift);
  const newPrice = {
    from: moment().toISOString(),
    to: moment().add(1, 'day').toISOString(),
    price: 0,
    fee: 0,
    deposit: 0,
    balance: 0,
    sellers: [],
    status: 'active',
    variant: currentService?.variants[0],
  };

  if (!currentService) {
    return <div>Servizio non trovato</div>;
  }

  const handleServiceChange = (value: string) => {
    setService(value);
    setShift(services.find((s) => s.id === value)?.shifts[0].id);
  };

  const handleShiftChange = (value: string) => {
    setShift(value);
  };

  const handleItemResize = (
    item: string,
    date: number,
    direction: 'left' | 'right'
  ) => {
    const newDate = moment(date).utc(true);
    updatePolicy({
      id: item.toString(),
      data:
        direction === 'left'
          ? {
              from: new Date(newDate.toDate()).toISOString(),
            }
          : {
              to: subDays(new Date(newDate.toDate()), 1).toISOString(),
            },
    });
  };

  const handleCanvasClick = (s: string, d: number) => {
    const start = new Date(d);
    const end = addDays(start, 3);
    const group = groups.find((g) => g.id === s);
    setSelectedItem({
      ...newPrice,
      from: moment(start).utc().format(),
      to: moment(end).utc().format(),
      variant: group?.variant as Variant,
      sellers: group?.sellers as PriceDirectusUsers[],
    });
  };

  const handleItemMove = (
    item: string,
    _newStart: number,
    groupOrder: number
  ) => {
    const group = groups[groupOrder];
    if (group?.folder) return;
    const variant = group?.variant as Variant;
    const sellers = group?.sellers as PriceDirectusUsers[];

    const policy = data.find((p) => p.id === item);
    if (!policy) return;

    policy.from = moment(policy.from).utc().format();
    policy.to = moment(policy.to).utc().format();

    const duration = moment(policy.to).diff(moment(policy.from), 'days');

    const newStart = moment(_newStart).utc(true);
    const newEnd = newStart.clone().add(duration || 0, 'days');

    updatePolicy({
      id: item.toString(),
      data: {
        from: new Date(newStart.toDate()).toISOString(),
        to: new Date(newEnd.toDate()).toISOString(),
        variant: variant,
        sellers: sellers.map((s) => ({
          directus_users_id: s.directus_users_id.id,
        })) as unknown as PriceDirectusUsers[],
      },
    });
  };

  const handleItemClick = (item: string) => {
    setSelectedItem(data.find((p) => p.id === item));
  };

  return (
    <Layout>
      <div className="block w-full h-full">
        <Heading
          className="p-4"
          title="Configurazione prezzi"
          side={
            <Button variant="outline" onClick={() => setSelectedItem(newPrice)}>
              <PlusCircledIcon className="w-4 h-4 mr-2" /> Aggiungi prezzo
            </Button>
          }
        />
        <div className="p-4">
          <div className="flex gap-4">
            <ServicePicker
              handleServiceChange={handleServiceChange}
              service={service}
              services={services}
            />

            <ShiftPicker
              handleShiftChange={handleShiftChange}
              service={service}
              services={services}
              shift={shift}
            />
          </div>
        </div>

        {isLoading || isLoadingServices ? (
          <div className="flex items-center justify-center h-full">
            <Loader2 className="w-4 h-4 animate-spin" />
          </div>
        ) : (
          <Timeline
            className={isUpdating || isFetching ? 'opacity-50' : ''}
            canMove={!isUpdating && !isFetching}
            canResize={!isUpdating && !isFetching ? 'both' : false}
            groups={groups}
            items={timelineItems}
            defaultTimeStart={new Date().getTime()}
            defaultTimeEnd={addDays(new Date(), 30).getTime()}
            minZoom={30 * 24 * 60 * 60 * 1000}
            maxZoom={12 * 30 * 24 * 60 * 60 * 1000}
            itemHeightRatio={0.8}
            canChangeGroup={true}
            dragSnap={1000 * 60 * 60 * 24}
            stackItems={true}
            lineHeight={60}
            itemTouchSendsClick={false}
            horizontalLineClassNamesForGroup={(group) =>
              group.folder ? ['row-root'] : []
            }
            onItemResize={handleItemResize}
            onCanvasClick={handleCanvasClick}
            onItemMove={handleItemMove}
            onItemClick={handleItemClick}
            itemRenderer={Item}
            groupRenderer={({ group }) => {
              const isFolder = group.folder;

              if (isFolder) {
                return;
              }

              return (
                <div className="flex flex-col items-start justify-center h-full px-2 space-y-1 text-xs break-words">
                  <div className="text-sm font-semibold truncate text-slate-500">
                    {group.title}
                  </div>
                </div>
              );
            }}
          >
            <TimelineHeaders className="sticky top-[60px]">
              <SidebarHeader>
                {({ getRootProps }) => (
                  <div
                    {...getRootProps({})}
                    className="flex flex-col items-center justify-center text-slate-900"
                  >
                    <div className="relative w-full p-1">
                      <input
                        type="text"
                        placeholder="Cerca"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        className="w-full px-1 py-1 text-xs border rounded-md text-slate-800 border-slate-300 placeholder:text-slate-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      />
                      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                        <svg
                          className="w-4 h-4 text-slate-400"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                )}
              </SidebarHeader>
              <DateHeader unit="primaryHeader" />
              <DateHeader />
              <TimelineMarkers>
                <TodayMarker interval={1000 * 60 * 60 * 24}>
                  {({ styles }) => (
                    <div
                      style={{
                        ...styles,
                        backgroundColor: 'transparent',
                      }}
                      className="z-10 pl-2 text-xs font-semibold uppercase bg-transparent border-l border-dotted border-slate-600 text-slate-600"
                    >
                      today
                    </div>
                  )}
                </TodayMarker>
              </TimelineMarkers>
            </TimelineHeaders>
          </Timeline>
        )}
      </div>
      {selectedItem && currentShift && currentService && (
        <EditPrice
          onClose={() => setSelectedItem(undefined)}
          isOpen={!!selectedItem}
          initialData={selectedItem}
          service={currentService}
          shift={currentShift}
          onSubmit={async () => {
            await refetch();
          }}
        />
      )}
    </Layout>
  );
}
