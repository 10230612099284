import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AnimatePresence } from 'framer-motion';
import { Suspense } from 'react';
import { Loader2 } from 'lucide-react';
import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import store from './store';
import { TooltipProvider } from '@/ui/tooltip';
import { ThemeProvider } from './theme-provider';
import Login from './pages/login';
import Booking from './pages/booking';
import Reservations from './pages/reservations';
import Home from './pages/home';
import Reservation from './pages/reservation';
import Ticket from './pages/ticket';
import PricePolicies from './pages/price-policies';
import Availability from './pages/availability';

// Initialize i18next
i18next
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'it', // Default language
    lng: 'it',
    debug: true, // Enable debug mode for development
    interpolation: {
      escapeValue: false, // React already does escaping
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    ns: ['translations'],
    defaultNS: 'translations',
  });

const PrivateRoutes = () => {
  const { isAuthenticated, initializing } = store();

  if (initializing) return null;
  if (isAuthenticated === true) return <Outlet />;
  return <Login />;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      { path: '/', element: <Home /> },
      { path: 'book/:id', element: <Booking /> },
      { path: 'reservations', element: <Reservations /> },
      { path: 'reservations/:id', element: <Reservation /> },
      { path: 'ticket/:id', element: <Ticket /> },
      { path: 'supplier/price-policies', element: <PricePolicies /> },
      { path: 'supplier/availability', element: <Availability /> },
    ],
  },
]);

export function App() {
  const { initializing } = store((s) => s);
  const queryClient = new QueryClient();

  return !initializing ? (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider defaultTheme="light" storageKey="theme">
        <TooltipProvider>
          <AnimatePresence mode="sync" initial={true}>
            <Suspense fallback={<Loader2 className="w-4 h-4 animate-spin" />}>
              <RouterProvider
                router={router}
                future={{ v7_startTransition: true }}
              />
            </Suspense>
          </AnimatePresence>
        </TooltipProvider>
      </ThemeProvider>
    </QueryClientProvider>
  ) : (
    <div className="flex items-center justify-center h-screen">
      <Loader2 className="w-4 h-4 animate-spin" />
    </div>
  );
}

export default App;
