interface StatusBadgeProps {
  color: 'emerald' | 'red';
  label: string;
}

export function StatusBadge({ color, label }: StatusBadgeProps) {
  if (color === 'emerald') {
    return (
      <div className="mt-1 flex items-center gap-x-1.5">
        <div className="flex-none rounded-full bg-emerald-500/20 p-1">
          <div className="size-1.5 rounded-full bg-emerald-500"></div>
        </div>
        <p className="text-xs/5 text-emerald-500">{label}</p>
      </div>
    );
  }

  if (color === 'red') {
    return (
      <div className="mt-1 flex items-center gap-x-1.5">
        <div className="flex-none rounded-full bg-red-500/20 p-1">
          <div className="size-1.5 rounded-full bg-red-500"></div>
        </div>
        <p className="text-xs/5 text-red-500">{label}</p>
      </div>
    );
  }

  return null;
}
