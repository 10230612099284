import { Price, PriceDirectusUsers } from '@/global';
import { addDays } from 'date-fns';
import { Variant } from 'framer-motion';

export interface TimelineItem {
  id: string;
  group: string;
  title: string;
  start_time: number;
  end_time: number;
  stackItems: boolean;
  canChangeGroup?: boolean;
  itemProps: {
    color: string;
    sellers: Partial<PriceDirectusUsers>[];
    error: boolean;
  };
  isFolder: boolean;
  data?: Price;
}

export const getSellerIds = (sellers: PriceDirectusUsers[]) => {
  return (
    sellers
      ?.map((s) => s.directus_users_id?.id)
      .filter(Boolean)
      .sort()
      .join('-') || ''
  );
};

export const buildUniqueGroups = (data: Price[], filter?: string) => {
  const uniqueGroups = new Map<
    string,
    {
      id: string;
      title: string;
      subtitle: string;
      stackItems: boolean;
      sort: number;
      sellersId: string;
      folder: boolean;
      sellers?: PriceDirectusUsers[];
      variant?: Partial<Variant>;
    }
  >();

  // First loop to build unique groups
  for (const policy of data) {
    const sellerIds = getSellerIds(policy.sellers);
    const groupId = `${sellerIds}-${policy.variant?.id}`;
    const sellerNames = policy.sellers
      ?.map(({ directus_users_id }) =>
        directus_users_id?.company
          ? directus_users_id?.company.trim()
          : `${directus_users_id?.first_name?.trim()} ${directus_users_id?.last_name?.trim()}`
      )
      .join(', ');

    const searchableKeys = policy.sellers
      ?.map(
        ({ directus_users_id }) =>
          `${directus_users_id?.company?.trim()} ${directus_users_id?.first_name?.trim()} ${directus_users_id?.last_name?.trim()}`
      )
      .join(', ');

    const shouldBePushed =
      !filter || searchableKeys?.toLowerCase().includes(filter.toLowerCase());

    if (!uniqueGroups.has(sellerIds) && shouldBePushed) {
      uniqueGroups.set(sellerIds, {
        id: sellerIds,
        title: sellerNames,
        subtitle: '',
        stackItems: true,
        sellersId: sellerIds,
        folder: true,
        sort: 0,
        sellers: policy.sellers,
        variant: policy.variant,
      });
    }

    if (shouldBePushed) {
      uniqueGroups.set(groupId, {
        id: groupId,
        title: policy.variant?.name,
        subtitle: '',
        stackItems: true,
        sort: policy.variant?.sort || 0,
        sellersId: sellerIds,
        folder: false,
        sellers: policy.sellers,
        variant: policy.variant,
      });
    }
  }

  return Array.from(uniqueGroups.values()).sort((a, b) => {
    if (a.sellersId === b.sellersId) {
      return (a.sort || 0) - (b.sort || 0);
    }
    return a.sellersId < b.sellersId ? -1 : 1;
  });
};

export const createTimelineItems = (data: Price[]) => {
  const timelineItems: TimelineItem[] = data.map((policy) => {
    const sellerIds = getSellerIds(policy.sellers);

    // validate if the policy overlaps with another policy
    const overlaps = data.some((otherPolicy) => {
      if (otherPolicy.id === policy.id) return false;

      const otherSellerIds = getSellerIds(otherPolicy.sellers);
      const sameGroup =
        `${otherSellerIds}-${otherPolicy.variant?.id}` ===
        `${sellerIds}-${policy.variant?.id}`;

      if (!sameGroup) return false;

      const policyStart = new Date(policy.from || '').getTime();
      const policyEnd = new Date(policy.to || '').getTime();
      const otherStart = new Date(otherPolicy.from || '').getTime();
      const otherEnd = new Date(otherPolicy.to || '').getTime();

      return (
        (policyStart >= otherStart && policyStart <= otherEnd) ||
        (policyEnd >= otherStart && policyEnd <= otherEnd) ||
        (policyStart <= otherStart && policyEnd >= otherEnd)
      );
    });

    return {
      id: policy.id,
      group: `${sellerIds}-${policy.variant?.id}`,
      title: `${policy.price}€ - ${policy.fee}€ - ${policy.name}`,
      start_time: new Date(policy.from || '').getTime(),
      end_time: new Date(policy.to || '').getTime() + 1000 * 60 * 60 * 24,
      stackItems: true,
      itemProps: {
        color: overlaps ? 'rgb(185, 28, 28)' : policy.color || 'transparent',
        error: overlaps,
        sellers: policy?.sellers || [],
      },
      data: policy,
      isFolder: false,
    };
  });

  return timelineItems;
};

export const addFolderItems = (
  timelineItems: TimelineItem[],
  groups: ReturnType<typeof buildUniqueGroups>
) => {
  groups
    .filter((g) => g.folder)
    .forEach((group) => {
      timelineItems.push({
        id: group.id,
        group: group.id,
        title: group.title,
        start_time: 0,
        end_time: addDays(new Date(), 30000).getTime(),
        stackItems: true,
        canChangeGroup: false,
        itemProps: {
          sellers: group?.sellers || [],
          color: 'transparent',
          error: false,
        },
        isFolder: true,
        data: undefined,
      });
    });

  return timelineItems;
};
