import { Shift } from '@/global';
import { cn } from '@/lib/utils';
import { useTranslation } from 'react-i18next';

interface CalendarShiftsProps {
  shifts: Shift[];
  selectedShift: string;
  onSelected: (shift: string) => void;
  selectedDate: Date;
}

export default function CalendarShifts({
  shifts,
  selectedShift,
  onSelected,
  selectedDate,
}: CalendarShiftsProps) {
  const { t } = useTranslation();

  const isShiftDisabled = (shift: Shift) => {
    return (
      selectedDate &&
      new Date().toDateString() === selectedDate.toDateString() &&
      new Date().toLocaleTimeString('en-US', { hour12: false }) > shift.to!
    );
  };

  return (
    <div className="relative w-full h-full p-3 space-y-4">
      {shifts.map((shift) => (
        <ShiftButton
          key={shift.id}
          shift={shift}
          isDisabled={isShiftDisabled(shift)}
          isSelected={selectedShift === shift.id}
          onSelected={onSelected}
          t={t}
        />
      ))}
    </div>
  );
}

interface ShiftButtonProps {
  shift: Shift;
  isDisabled: boolean;
  isSelected: boolean;
  onSelected: (shift: string) => void;
  t: any;
}

function ShiftButton({
  shift,
  isDisabled,
  isSelected,
  onSelected,
  t,
}: ShiftButtonProps) {
  return (
    <button
      aria-label={shift.name ?? undefined}
      disabled={isDisabled}
      className={cn(
        'w-full text-left relative block bg-white p-2 px-4 flex shadow-sm',
        {
          'opacity-50 cursor-not-allowed': isDisabled,
        }
      )}
      onClick={() => onSelected(shift.id)}
    >
      <span className="flex items-center">
        <span className="flex flex-col text-sm">
          <span className="text-xl font-medium text-gray-900 capitalize">
            {shift.name}
          </span>
          <span className="text-gray-500">
            <span className="block sm:inline">
              {t('components.booking-form.departure-time.from', {
                time: shift.from,
              })}
            </span>
            <span className="hidden sm:mx-1 sm:inline" aria-hidden="true">
              &middot;
            </span>
            <span className="block sm:inline">
              {t('components.booking-form.departure-time.to', {
                time: shift.to,
              })}
            </span>
          </span>
        </span>
      </span>
      <span
        className="absolute border-2 rounded-lg pointer-events-none -inset-px"
        aria-hidden="true"
      ></span>
      <svg
        className={cn('size-5 text-slate-600 absolute top-2 right-2', {
          hidden: !isSelected,
        })}
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
        data-slot="icon"
      >
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.857-9.809a.75.75 0 0 0-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z"
          clipRule="evenodd"
        />
      </svg>
    </button>
  );
}