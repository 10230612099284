'use client';

import { QuoteRequest, Service } from '@/lib/api/types';
import { useRequestQuotes } from './use-request-quote';
import { format } from 'date-fns';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/ui/table';
import { Shift } from '@/global';
import { Loader2 } from 'lucide-react';
import { CheckCircledIcon, CrossCircledIcon } from '@radix-ui/react-icons';
import { Button } from '@/ui/button';

interface AvailabilityPreviewProps {
  quoteRequest: QuoteRequest;
  service: Service;
  days?: number;
  onUpdate?: (date: Date, shift: Shift) => void;
}

export function AvailabilityPreview({
  quoteRequest,
  service,
  days = 3,
  onUpdate,
}: AvailabilityPreviewProps) {
  const requests = generateQuoteRequests(quoteRequest, service, days);
  const { isPending, quoteResponses = [] } = useRequestQuotes(requests);

  if (isPending) {
    return (
      <div className="flex items-center justify-center h-full">
        <Loader2 className="w-6 h-6 animate-spin" />
      </div>
    );
  }

  return (
    <div className="border rounded-md">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead></TableHead>
            {generateTableHeaders(quoteRequest, days)}
          </TableRow>
        </TableHeader>
        <TableBody>
          {service.shifts.map((shift) => (
            <TableRow key={shift.id} className="hover:bg-white">
              <TableCell>{shift.name}</TableCell>
              {generateTableCells(quoteRequest, shift, days, quoteResponses, onUpdate)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

function generateQuoteRequests(quoteRequest: QuoteRequest, service: Service, days: number) {
  return service.shifts.flatMap((shift: Shift) =>
    Array.from({ length: days }, (_, i) => {
      const date = new Date(quoteRequest.date);
      date.setDate(date.getDate() + i);
      return {
        ...quoteRequest,
        date,
        shift: shift.id,
      };
    })
  );
}

function generateTableHeaders(quoteRequest: QuoteRequest, days: number) {
  return Array.from({ length: days }, (_, i) => {
    const date = new Date(quoteRequest.date);
    date.setDate(date.getDate() + i);
    return (
      <TableHead key={i}>
        <Button variant="ghost" className="w-full">
          <div className="flex flex-col items-center justify-center">
            <div className="text-lg font-semibold leading-none">
              {format(date, 'd')}
            </div>
            <div className="text-xs uppercase text-slate-800">
              {format(date, 'MMM')}
            </div>
          </div>
        </Button>
      </TableHead>
    );
  });
}

function generateTableCells(
  quoteRequest: QuoteRequest,
  shift: Shift,
  days: number,
  quoteResponses: any[],
  onUpdate?: (date: Date, shift: Shift) => void
) {
  return Array.from({ length: days }, (_, i) => {
    const requestIndex = i + (shift.id === quoteRequest.shift ? 0 : days);
    const date = new Date(quoteRequest.date);
    date.setDate(date.getDate() + i);
    return (
      <TableCell key={i} className="text-center">
        {quoteResponses[requestIndex]?.status === 'success' ? (
          <Button
            variant="outline"
            className="text-green-700 hover:bg-green-50"
            onClick={() => onUpdate?.(date, shift)}
          >
            <CheckCircledIcon className="w-4 h-4" />
          </Button>
        ) : (
          <Button
            variant="outline"
            disabled
            className="text-red-700 hover:bg-red-50"
          >
            <CrossCircledIcon className="w-4 h-4" />
          </Button>
        )}
      </TableCell>
    );
  });
}