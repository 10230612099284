'use client';

import { Button } from '@/ui/button';
import { Calendar as Cal } from '@/ui/calendar';
import { it } from 'date-fns/locale';
import { Input } from '@/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/ui/select';
import { Price, PriceDirectusUsers, Service, Shift } from '@/global';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/ui/dialog';
import { Formik } from 'formik';
import { Label } from '@/ui/label';
import { Checkbox } from '@/ui/checkbox';
import {
  createPricePolicy,
  deletePricePolicy,
  updatePricePolicy,
} from '@/lib/api';
import { normalizeDate } from '@/lib/utils';
import { useState } from 'react';

interface PriceFormProps {
  initialData: Partial<Price>;
  service: Service;
  shift: Shift;
  onSubmit: (values: Partial<Price>) => Promise<void>;
  isOpen: boolean;
  onClose: () => void;
}

export default function PriceForm({
  initialData,
  service,
  shift,
  onSubmit,
  isOpen,
  onClose,
}: PriceFormProps) {
  const [isDuplicateOpen, setIsDuplicateOpen] = useState(false);

  const handleFormSubmit = async (data: Partial<Price>) => {
    data.sellers = data.sellers?.map((s) => ({
      directus_users_id: { id: s.directus_users_id.id },
    })) as PriceDirectusUsers[];
    try {
      if (!data.id) {
        data.service = service.id;
        data.shift = shift.id;
        await createPricePolicy(data);
      } else {
        await updatePricePolicy(data, data.id);
      }
      onClose();
      onSubmit(data);
    } catch (error) {
      console.error('Failed to update price:', error);
    }
  };

  const handleDelete = async () => {
    try {
      if (!initialData.id) return;
      if (!window.confirm('Are you sure you want to delete this price policy?'))
        return;
      await deletePricePolicy(initialData.id);
      onClose();
      onSubmit(initialData);
    } catch (error) {
      console.error('Failed to delete price:', error);
    }
  };

  const handleDuplicate = (form: any) => {
    setIsDuplicateOpen(true);
    if (!initialData) return;
    const duplicatedData = {
      ...initialData,
      id: undefined,
      name: `${initialData.name} (copy)`,
    };
    form.setValues(duplicatedData);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:min-w-[600px]">
        <DialogHeader>
          <DialogTitle className="text-xl font-semibold">
            {initialData?.id
              ? isDuplicateOpen
                ? 'Duplicate Price Policy'
                : 'Edit Price Policy'
              : 'Create Price Policy'}
          </DialogTitle>
        </DialogHeader>

        <div className="max-h-[60vh] overflow-y-scroll">
          <Formik
            initialValues={initialData}
            onSubmit={handleFormSubmit}
          >
            {(form) => (
              <form onSubmit={form.handleSubmit} className="block w-full">
                <div className="grid w-full gap-4 py-4">
                  <FormField
                    id="name"
                    label="Name"
                    name="name"
                    value={form.values.name || ''}
                    onChange={form.handleChange}
                  />

                  <div className="grid grid-cols-2 gap-4">
                    <FormField
                      id="price"
                      label="Price"
                      name="price"
                      type="number"
                      value={form.values.price ?? ''}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);
                        form.setFieldValue(
                          'price',
                          isNaN(value) ? null : Number(value.toFixed(2))
                        );
                      }}
                    />
                    <FormField
                      id="fee"
                      label="Fee"
                      name="fee"
                      type="number"
                      value={form.values.fee ?? ''}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);
                        form.setFieldValue(
                          'fee',
                          isNaN(value) ? null : Number(value.toFixed(2))
                        );
                      }}
                    />
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <FormField
                      id="deposit"
                      label="Deposit"
                      name="deposit"
                      type="number"
                      value={form.values.deposit ?? ''}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);
                        form.setFieldValue(
                          'deposit',
                          isNaN(value) ? null : Number(value.toFixed(2))
                        );
                      }}
                    />
                    <FormField
                      id="balance"
                      label="Balance"
                      name="balance"
                      type="number"
                      value={form.values.balance ?? ''}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);
                        form.setFieldValue(
                          'balance',
                          isNaN(value) ? null : Number(value.toFixed(2))
                        );
                      }}
                    />
                  </div>

                  <FormField
                    id="color"
                    label="Color"
                    name="color"
                    type="color"
                    value={form.values.color || '#000000'}
                    onChange={form.handleChange}
                    className="h-10 px-2 py-1"
                  />

                  <div className="flex flex-col gap-2">
                    <Label htmlFor="to">Period</Label>
                    <Cal
                      mode="range"
                      className="w-full"
                      initialFocus
                      locale={it}
                      selected={{
                        from: form.values.from
                          ? new Date(form.values.from)
                          : undefined,
                        to: form.values.to
                          ? new Date(form.values.to)
                          : undefined,
                      }}
                      showWeekNumber={false}
                      onSelect={(date) => {
                        const from = date?.from
                          ? normalizeDate(date.from)
                          : undefined;
                        const to = date?.to
                          ? normalizeDate(date.to)
                          : undefined;
                        form.setFieldValue('from', from);
                        form.setFieldValue('to', to);
                      }}
                      numberOfMonths={2}
                      defaultMonth={
                        form.values.from
                          ? new Date(form.values.from)
                          : undefined
                      }
                    />
                  </div>

                  <div className="flex flex-col gap-2">
                    <Label htmlFor="variant">Variant</Label>
                    <Select
                      name="variant"
                      onValueChange={(value) =>
                        form.setFieldValue('variant', value)
                      }
                      value={form.values.variant?.id}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select variant" />
                      </SelectTrigger>
                      <SelectContent>
                        {service.variants?.map((variant) => (
                          <SelectItem key={variant.id} value={variant.id}>
                            {variant.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>

                  <div className="flex flex-col gap-2">
                    <Label htmlFor="sellers">Sellers</Label>
                    <div className="flex flex-col gap-2">
                      {service.sellers?.map((seller) => (
                        <div
                          key={seller.id}
                          className="flex items-center space-x-2"
                        >
                          <Checkbox
                            id={seller.id.toString()}
                            checked={
                              !!form.values.sellers?.find(
                                (s) =>
                                  s.directus_users_id.id ===
                                  seller.directus_users_id.id
                              )
                            }
                            onCheckedChange={(checked) => {
                              const sellers = checked
                                ? [...(form.values.sellers ?? []), seller]
                                : form.values.sellers?.filter(
                                    (s) =>
                                      s.directus_users_id.id !==
                                      seller.directus_users_id.id
                                  );

                              form.setFieldValue('sellers', sellers);
                            }}
                          />
                          <Label
                            htmlFor={seller.id.toString()}
                            className="font-normal"
                          >
                            {typeof seller.directus_users_id === 'object' &&
                              (seller.directus_users_id.company
                                ? `${seller.directus_users_id.company} (${seller.directus_users_id.first_name})`
                                : seller.directus_users_id.first_name
                                ? `${seller.directus_users_id.first_name} ${seller.directus_users_id.last_name}`
                                : seller.directus_users_id.email)}
                          </Label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="flex justify-end">
                    <Button type="submit">Save changes</Button>
                    {!isDuplicateOpen && initialData?.id && (
                      <Button
                        type="button"
                        variant="outline"
                        className="ml-2"
                        onClick={() => handleDuplicate(form)}
                      >
                        Duplicate
                      </Button>
                    )}
                    {initialData?.id && (
                      <Button
                        type="button"
                        variant="destructive"
                        className="ml-2"
                        onClick={handleDelete}
                      >
                        Delete
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
}

interface FormFieldProps {
  id: string;
  label: string;
  name: string;
  type?: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<any>) => void;
  className?: string;
}

function FormField({
  id,
  label,
  name,
  type = 'text',
  value,
  onChange,
  className,
}: FormFieldProps) {
  return (
    <div className="flex flex-col gap-2">
      <Label htmlFor={id}>{label}</Label>
      <Input
        id={id}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        className={className}
      />
    </div>
  );
}