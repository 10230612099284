import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getRandomElementFromArray<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)];
}

export function formatDate(
  date: string | Date = new Date(),
  formatStr = 'PPP'
): string {
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  return format(dateObj, formatStr, { locale: it });
}

export function formatDateTime(
  date: string | Date = new Date(),
  formatStr = 'PPPpp'
): string {
  return format(date, formatStr, { locale: it });
}

export function getPropertyValueOrReference<T, K extends keyof T>(
  obj: T | string,
  key: K,
  fallback?: string
): string | T[K] {
  return typeof obj === 'string'
    ? fallback
      ? fallback
      : obj
    : String(obj[key]);
}

export function lightenColor(color: string, amount = 0.7): string {
  // Handle named colors by converting to hex first
  if (!/^#|rgb|hsl/.test(color)) {
    const ctx = document.createElement('canvas').getContext('2d');
    if (!ctx) return color;
    ctx.fillStyle = color;
    color = ctx.fillStyle;
  }

  // Handle hex colors
  if (color.startsWith('#')) {
    const hex = color.replace('#', '');
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    return `rgb(${r + Math.floor((255 - r) * amount)}, ${
      g + Math.floor((255 - g) * amount)
    }, ${b + Math.floor((255 - b) * amount)})`;
  }

  // Handle rgb/rgba colors
  if (color.startsWith('rgb')) {
    const [r, g, b, a] = color.match(/[\d.]+/g)?.map(Number) || [0, 0, 0, 1];
    return `rgba(${r + Math.floor((255 - r) * amount)}, ${
      g + Math.floor((255 - g) * amount)
    }, ${b + Math.floor((255 - b) * amount)}, ${a || 1})`;
  }

  // Handle hsl/hsla colors
  if (color.startsWith('hsl')) {
    const [h, s, l, a] = color.match(/[\d.]+/g)?.map(Number) || [0, 0, 0, 1];
    return `hsla(${h}, ${s}%, ${Math.min(100, l + amount * 100)}%, ${a || 1})`;
  }

  return color;
}

export function getContrastTextColor(backgroundColor: string): string {
  // Convert any color format to RGB
  let r: number, g: number, b: number;

  // Handle named colors by converting to hex first
  if (!/^#|rgb|hsl/.test(backgroundColor)) {
    const ctx = document.createElement('canvas').getContext('2d');
    if (!ctx) return '#000000';
    ctx.fillStyle = backgroundColor;
    backgroundColor = ctx.fillStyle;
  }

  // Handle hex colors
  if (backgroundColor.startsWith('#')) {
    const hex = backgroundColor.replace('#', '');
    r = parseInt(hex.slice(0, 2), 16);
    g = parseInt(hex.slice(2, 4), 16);
    b = parseInt(hex.slice(4, 6), 16);
  }
  // Handle rgb/rgba colors
  else if (backgroundColor.startsWith('rgb')) {
    [r, g, b] = backgroundColor.match(/\d+/g)?.map(Number) || [0, 0, 0];
  }
  // Handle hsl/hsla colors
  else if (backgroundColor.startsWith('hsl')) {
    const [h, s, l] = backgroundColor.match(/\d+/g)?.map(Number) || [0, 0, 0];
    // Convert HSL to RGB
    const c = ((1 - Math.abs((2 * l) / 100 - 1)) * s) / 100;
    const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    const m = l / 100 - c / 2;

    let rp: number, gp: number, bp: number;
    if (h < 60) [rp, gp, bp] = [c, x, 0];
    else if (h < 120) [rp, gp, bp] = [x, c, 0];
    else if (h < 180) [rp, gp, bp] = [0, c, x];
    else if (h < 240) [rp, gp, bp] = [0, x, c];
    else if (h < 300) [rp, gp, bp] = [x, 0, c];
    else [rp, gp, bp] = [c, 0, x];

    r = Math.round((rp + m) * 255);
    g = Math.round((gp + m) * 255);
    b = Math.round((bp + m) * 255);
  } else {
    return '#000000'; // Default to black if color format is not recognized
  }

  // Calculate relative luminance using sRGB
  const luminance =
    0.2126 * (r / 255) + 0.7152 * (g / 255) + 0.0722 * (b / 255);

  // Return white for dark backgrounds, black for light backgrounds
  return luminance > 0.5 ? '#000000' : '#FFFFFF';
}

export function normalizeDate(selectedDate: Date): Date {
  const utcDate = new Date(
    Date.UTC(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate()
    )
  );
  return utcDate;
}
