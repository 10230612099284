import { Availability } from '@/global';
import { createAvailability, setAvailability } from '@/lib/api';
import clsx from 'clsx';
import { Loader2 } from 'lucide-react';
import { useState, useRef } from 'react';

interface AvailabilityBlockProps {
  availability: Availability | undefined;
  serviceId?: string;
  shiftId?: string;
  date?: string;
  resourceId?: string;
}

export function AvailabilityBlock({
  availability,
  serviceId,
  shiftId,
  date,
  resourceId,
}: AvailabilityBlockProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(availability?.amount ?? 0);
  const [oldTotal, setOldTotal] = useState(availability?.amount ?? 0);
  const booked =
    availability?.lockers
      ?.filter((locker) => locker.status)
      .reduce((sum, locker) => sum + (locker.amount || 0), 0) ?? 0;

  const available = total - booked;

  const debounceRef = useRef<NodeJS.Timeout | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Math.max(0, parseInt(e.target.value) || 0);
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    setOldTotal(total);
    setTotal(value);

    debounceRef.current = setTimeout(() => {
      setIsLoading(true);
      const apiCall = availability
        ? setAvailability(availability.id, { amount: value })
        : createAvailability({
            amount: value,
            service: serviceId,
            shift: shiftId,
            resource: resourceId,
            date,
          });

      apiCall
        .then((e: Availability) => setTotal(e.amount ?? 0))
        .catch(() => setTotal(oldTotal))
        .finally(() => {
          setIsLoading(false);
        });
    }, 1500);
  };

  if (isLoading) {
    return (
      <div
        style={{ width: '100px', maxWidth: '100px', height: 'auto' }}
        className="flex items-center justify-center border-r border-gray-200 last:border-r-0"
      >
        <Loader2 className="size-5 animate-spin" />
      </div>
    );
  }

  return (
    <>
      <div
        className={clsx(
          'flex items-center p-2 space-x-1 border-r border-gray-200 last:border-r-0',
          {
            'border-t-2 border-t-green-500': available > booked,
            'border-t-2 border-t-red-500': available === 0,
            'border-t-2 border-t-amber-500':
              available < total / 3 && available > 0,
            'border-t-2 border-t-transparent': !availability,
          }
        )}
        style={{ width: '100px', maxWidth: '100px' }}
      >
        <input
          type="number"
          min="0"
          className="w-[40px] bg-slate-100 p-1 border-slate-300 rounded text-right [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
          value={total}
          onChange={(e) => {
            handleChange(e);
          }}
        />
        <div
          className={clsx('text-sm font-semibold', {
            'text-red-700': available === 0,
            'text-green-700': available > total / 3,
            'text-amber-700': available < total / 3 && available > 0,
          })}
        >
          / {booked}
        </div>
      </div>
    </>
  );
}
