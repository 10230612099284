import { Reservations } from '@/global';
import {
  calculateBalance,
  calculateDeposit,
  calculateFee,
  calculateTotal,
  calculateValue,
  getAllVariants,
} from '@/lib/reservation';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export function useDownloadCsv() {
  const { t } = useTranslation();

  return useCallback(
    (data: Reservations[]) => {
      return mapDatatoCSV(data, t);
    },
    [t]
  );
}

function mapDatatoCSV(
  data: Reservations[],
  t: ReturnType<typeof useTranslation>['t']
) {
  const variants = getAllVariants(data);

  const columns = [
    t('reservation.columns.booked-date'),
    t('reservation.columns.booking-date'),
    t('reservation.columns.code'),
    t('reservation.columns.customer-name'),
    t('reservation.columns.phone'),
    t('reservation.columns.email'),
    t('reservation.columns.service'),
    t('reservation.columns.shift'),
    t('reservation.columns.status'),
    t('reservation.columns.total'),
    t('reservation.columns.fee'),
    t('reservation.columns.value'),
    t('reservation.columns.deposit'),
    t('reservation.columns.balance'),
    ...variants.map((v) =>
      t(`reservation.columns.variant`, {
        variant: v.name,
        service: v.service.name,
      })
    ),
  ];

  const dataRows = data.map((r: Reservations) => {
    return [
      r.booked_date,
      r.booking_date,
      r.code,
      r.customer_name.replace(/,/g, ' '),
      r.customer_phone.replace(/,/g, ' '),
      r.customer_email.replace(/,/g, ' '),
      r.service.name.replace(/,/g, ' '),
      r.shift?.name?.replace(/,/g, ' ') ?? '',
      r.status === 'false'
        ? t('common.reservation.void')
        : t('common.reservation.active'),
      calculateTotal(r),
      calculateFee(r),
      calculateValue(r),
      calculateDeposit(r),
      calculateBalance(r),
      ...variants.map((v) => {
        const variant = r.variants.find((vr) => vr.variant.id === v.id);
        return variant?.amount ?? '0';
      }),
    ];
  });

  const headers = columns.join(',');
  const rows = dataRows.map((row) => row.join(',')).join('\n');

  const csvContent = headers + '\n' + rows;
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'reservations.csv';
  a.click();
}
