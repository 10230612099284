import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface ErrorScreenProps {
  message: string;
}

export default function ErrorScreen({ message }: ErrorScreenProps) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center h-screen space-y-4">
      <img src="/paper-boat.png" alt="error" className=" mb-4" />
      <h1 className="text-2xl font-semibold leading-6 text-gray-900">
        {t('components.error-screen.error')}
      </h1>
      <p className="mt-2 max-w-4xl text-sm text-gray-500">{message}</p>
      <Link to="/">{t('components.error-screen.go-to-homepage')}</Link>
    </div>
  );
}
