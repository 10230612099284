import { clsx } from 'clsx';
import { AlertTriangleIcon, FolderIcon } from 'lucide-react';
import { TimelineItem } from './utils';

export default function Item({
  item,
  itemContext,
  getItemProps,
}: {
  item: TimelineItem;
  itemContext: {
    selected: boolean;
    dimensions: {
      height: number;
    };
    title: string;
  };
   
  getItemProps: (props: any) => any;
}) {
  const props = {
    ...getItemProps({
      style: {
        borderLeftWidth: itemContext.selected ? 10 : 0,
        borderRightWidth: itemContext.selected ? 10 : 0,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderColor: itemContext.selected ? '#000' : 0,
        background: item.itemProps.color,
        zIndex: 1,
      },
      className: clsx('rct-item rounded-lg', {
        'border-slate-700': itemContext.selected,
        'border-transparent': !itemContext.selected,
      }),
    }),
  };

  if (item.isFolder) {
    return (
      <div {...props}>
        <div
          className="flex items-center h-full bg-white min-w-auto rct-item-content"
          style={{ height: itemContext.dimensions.height }}
        >
          <div className="flex items-center h-full gap-2 font-semibold text-slate-500">
            <FolderIcon className="w-4 h-4" />
            <div className="text-sm truncate">{itemContext.title}</div>{' '}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div {...props}>
      <div
        style={{
          height: itemContext.dimensions.height,
          paddingLeft: 8,
        }}
        className={clsx('overflow-hidden rct-item-content', {
          'bg-red-700 animate-pulse': item.itemProps.error,
        })}
        key={`${item.id}-content`}
      >
        <div className="flex items-center h-full gap-3 px-2 rounded-lg shrink-1 text-shadow-sm bg-slate-900/50">
          {item.itemProps.error && <AlertTriangleIcon className="w-5 h-5" />}
          <div className="shrink-0">
            <div className="text-xs leading-none uppercase">Costo</div>
            <div className="text-lg leading-none">
              {item.data?.price}
              <span className="text-xs"> &euro;</span>
            </div>
          </div>

          <div className=" shrink-0">
            <div className="text-xs leading-none uppercase">Fee</div>
            <div className="text-lg leading-none">
              {item.data?.fee}
              <span className="text-xs"> &euro;</span>
            </div>
          </div>

          <div className="shrink-0">
            <div className="text-xs leading-none uppercase">Total</div>
            <div className="text-lg leading-none">
              {item?.data?.price || 0 + (item?.data?.fee ?? 0)}
              <span className="text-xs"> &euro;</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
