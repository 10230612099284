import { PriceResponse, QuoteRequest } from '@/lib/api/types';
import { useTranslation } from 'react-i18next';
import { getTotalRequestedAmount } from './utils';

export function PriceTotals({
  quoteRequest,
  prices,
}: {
  quoteRequest: QuoteRequest;
  prices: PriceResponse;
}) {
  const { t } = useTranslation();

  const totalAmount = Object.entries(quoteRequest.variants)
    .reduce((total, [variantId, quantity]) => {
      return total + (prices?.prices?.[variantId]?.total ?? 0) * quantity;
    }, 0)
    .toFixed(2);

  return (
    <div className="rounded-lg border p-4 space-y-2">
      <TotalRow
        label={t('components.booking-form.totals.selected-items')}
        value={getTotalRequestedAmount(quoteRequest)}
      />
      <TotalRow
        label={t('components.booking-form.totals.total-amount')}
        value={`€ ${totalAmount}`}
        isBold
      />
    </div>
  );
}

function TotalRow({
  label,
  value,
  isBold = false,
}: {
  label: string;
  value: string | number;
  isBold?: boolean;
}) {
  return (
    <div className="flex justify-between items-center">
      <span className="text-sm font-medium">{label}</span>
      <span className={`text-sm ${isBold ? 'font-semibold' : ''}`}>{value}</span>
    </div>
  );
}